.birthday-box-shadow{
    box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
    object-fit: cover;
    position: relative;
}
.be-a-donner{
  
    object-fit: cover;
}
.birthday-info{
    position: absolute;
    top:0;
    left: 35px;
}