
.widthslider{
    background:none;
    width: 31.5%;
    height: auto;
    text-align: left;
    /* width: 100% !important; */
}


@media only screen and (max-width: 600px) {
.widthslider {
        width: 100%;
        /* width: 100% !important; */}
    }