@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Akhand";
  src: local("Akhand"), url("./Assets/font/Akhand.ttf") format("truetype");
  font-weight: normal;
}
@font-face {
  font-family: "solaiman";
  src: local("solaiman"), url("./Assets/font/soleman.ttf") format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "hasan";
  src: local("hasan"), url("./Assets/font/hasan.ttf") format("truetype");
  font-weight: normal;
}

@import "~react-image-gallery/styles/css/image-gallery.css";
@font-face {
  font-family: banlgaMain;
  src: url();
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Akhand, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.main-color {
  background-color: #9e0c00;
}
.online::before {
  background: green !important;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
#solaiman-font {
  font-family: solaiman;
}

.family-member-social {
  position: absolute;
  bottom: -39px;
  left: 50%;
  transform: translate(-50%, -50%);
}

.home-heading-font {
  font-family: Akhand;
}
/* best blood doner */

.our-team {
  padding: 30px 0 40px;
  /* margin-bottom: 30px; */
  background-color: #f7f5ec;
  text-align: center;
  overflow: hidden;
  position: relative;
}

.our-team .picture {
  display: inline-block;
  height: 130px;
  width: 130px;
  margin-bottom: 20px;
  z-index: 1;
  position: relative;
}

.our-team .picture::before {
  content: "";
  width: 100%;
  height: 0;
  border-radius: 50%;
  background-color: #231557;
  position: absolute;
  bottom: 135%;
  right: 0;
  left: 0;
  opacity: 0.9;
  transform: scale(3);
  transition: all 0.3s linear 0s;
}

.our-team:hover .picture::before {
  height: 100%;
}

.our-team .picture::after {
  content: "";
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: #231557;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}

.our-team .picture img {
  width: 100%;
  height: auto;
  border-radius: 50%;
  transform: scale(1);
  transition: all 0.9s ease 0s;
}

.our-team:hover .picture img {
  box-shadow: 0 0 0 14px #f7f5ec;
  transform: scale(0.7);
}

.our-team .title {
  display: block;
  font-size: 15px;
  color: #4e5052;
  text-transform: capitalize;
}

.our-team .profile-footer {
  width: 100%;
  padding: 5px 0;
  margin: 0;
  background-color: #231557;
  position: absolute;
  bottom: -100px;
  left: 0;
  transition: all 0.5s ease 0s;
  color: #fff;
}

.our-team:hover .profile-footer {
  bottom: 0;
}

/* donner profile cover */
/* .donner-cover{
background: rgba(0, 0, 0, 0.4);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(9px);
  -webkit-backdrop-filter: blur(9px);
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.18);
} */

/* for pagination */
.pagination {
  display: flex;
  justify-content: center;
  margin-bottom: 5rem;
  gap: 5px;
  margin-top: 3rem;
}

.pagination-active a {
  background-color: #5ab1bb;
  color: #fff;
}

.activity-sigle-post {
  color: #f7f5ec;
  font-size: 18px;
}
.offline::before {
  background: #9e0c00 !important;
}

.active {
  background-color: #9e0c00;
  color: #fff;
}

/* cover */
.cover-body {
  background-color: #000;
  overflow: hidden;
}

.drop-margin {
  margin: 130px auto;
  color: #fff;
}
.cover-title {
  color: #fff;
  text-align: center;
  top: 500%;
  right: 50%;
  transform: translateX(50%, 50%);
  display: none;
}

.drop {
  position: relative;
  width: 20px;
  height: 20px;
  top: -30px;
  margin: 0 auto;
  background: #f00;
  -moz-border-radius: 20px;
  -webkit-border-radius: 20px;
  border-radius: 20px;
  -moz-animation-name: drip;
  -webkit-animation-name: drip;
  animation-name: drip;
  -moz-animation-timing-function: cubic-bezier(1, 0, 0.91, 0.19);
  -webkit-animation-timing-function: cubic-bezier(1, 0, 0.91, 0.19);
  animation-timing-function: cubic-bezier(1, 0, 0.91, 0.19);
  -moz-animation-duration: 3s;
  -webkit-animation-duration: 3s;
  animation-duration: 3s;
  -moz-animation-iteration-count: infinite;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
}

.drop:before {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 30px solid rgba(255, 0, 0, 1);
  top: -22px;
}

.wave {
  position: relative;
  opacity: 0;
  top: 0;
  width: 2px;
  height: 1px;
  border: #f00 7px solid;
  -moz-border-radius: 300px / 150px;
  -webkit-border-radius: 300px / 150px;
  border-radius: 300px / 150px;
  -moz-animation-name: ripple;
  -webkit-animation-name: ripple;
  animation-name: ripple;
  -moz-animation-delay: 3s;
  -webkit-animation-delay: 3s;
  animation-delay: 3s;
  -moz-animation-duration: 3s;
  -webkit-animation-duration: 3s;
  animation-duration: 3s;
  -moz-animation-iteration-count: infinite;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
}

.wave:after {
  content: "";
  position: absolute;
  opacity: 0;
  top: -5px;
  left: -5px;
  width: 2px;
  height: 1px;
  border: #f00 5px solid;
  -moz-border-radius: 300px / 150px;
  -webkit-border-radius: 300px / 150px;
  border-radius: 300px / 150px;
  -moz-animation-name: ripple-2;
  -webkit-animation-name: ripple-2;
  animation-name: ripple-2;
  -moz-animation-duration: 3s;
  -webkit-animation-duration: 3s;
  animation-duration: 3s;
  -moz-animation-iteration-count: infinite;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
}

.navbar-end {
  width: 65% !important;
}

@keyframes ripple {
  from {
    opacity: 1;
  }

  to {
    width: 600px;
    height: 300px;
    border-width: 1px;
    top: -100px;
    opacity: 0;
  }
}

@keyframes ripple-2 {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  100% {
    width: 200px;
    height: 100px;
    border-width: 1px;
    top: 100px;
    left: 200px;
  }
}

@keyframes drip {
  to {
    top: 190px;
  }
}

.swal-text {
  color: #000 !important;
  font-family: solaiman;
}
