
  .swiper-container {
    width: 100%;
    padding-top: 3rem;
    padding-bottom: 3rem;
  }

  .swiper-slide {
    display: grid;
    text-align: center;
    padding: 0.5rem 1rem;
    background: #fffe;
    border-radius: 8px;
    padding: 5px;
    width: 80vh;
    height: 80vh;
  }
  
  .swiper-slide img {
    display: block;
    width:100%;
    height:100%;
    justify-self: center;
    margin-top: 0.6rem;
  }

    @media only screen and (max-width: 600px) {
      .swiper-slide {
        
          width: 68%;
          height: 68%;
        }
    }
 